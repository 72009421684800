.alignCenter{
    text-align: center;
}

.pMargin{
   margin-top: 12px; 
}

.reportbar{
    margin: 0.5rem 0;
    text-align: right;
    justify-content: flex-end;

    .summary{
        font-weight: 600;
    }
}
@primary-color: #945926;@btn-font-size-lg: 18px;@btn-height-lg: 50px;@input-height-lg: 50px;@layout-body-background: #fafafa;@layout-footer-background: #f0f2f5;@menu-dark-bg: #6a3024;@menu-dark-submenu-bg: #945926;@layout-sider-background: #6a3024;@breadcrumb-font-size: 18px;@breadcrumb-icon-font-size: 18px;