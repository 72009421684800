.layout{
    height: 100%;
}

  .header{
      font-weight: bold;
  }

.customHeader{
    padding-left: 0px;
    background-color: #fff;
}

.logo{
    background: url('./img/logo_top.png') no-repeat;
    background-size: 64px;
    background-color: #6a3024;
    height: 64px;
    border-radius: 0px;
    margin-left: 6px;
    width: 160px;
    float: left;
}

.title{
    font-size: x-large;
    margin-left: 12px;
    float: left;
    color:   #6a3024;
}

.headerMenuItem{
    font-size: 18;
    color: '#6a3024'
}


.backward{
    text-align: right;
}

.backward a{
    color: #fff;
    font-size: 16px;
}

.backward a i{
    font-size: 26px;
}

.content{
    min-height: 360px;
}



.home{
    margin: 12px auto;
    text-align: center;
    width: 100%;
    flex:1 0 auto;
    background-color: #F8F8FF;
}

.procLst{
    margin: 12px auto;
    text-align: center;
    width: 100%;
    flex:1 0 auto;
    background-color: #F8F8FF;
    padding-right: 12px;
}



.ant-layout-has-sider .trigger {
    font-size: 22px;
    line-height: 64px;
    padding: 0 16px;
    cursor: pointer;
    transition: color .3s;
    color: #6a3024;
}

.ant-layout-has-sider .trigger:hover {
    color: #945926;
}
  

.footer{
    text-align: center;
}

.marginBto1rem{
    margin-bottom: 1.2rem
}

.breadcrumbCustom{
    text-align: left;
    margin-left: 3%;
}

.right{
    margin-bottom: 1.2rem;
    text-align: right;
}

.marginRight12px{
    margin-right: 12px;
}

.largeFont{
    font-size: initial;
}

.childbg{
    background-color: #efcbadc9;
}

.maQR{
    word-wrap:break-word;
    word-break:normal;
    font-size: 2em;
    font-weight: bold;
}