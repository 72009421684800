.login{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: #f3f3f3;
    .login-form{
      padding: 30px 30px;
      box-shadow: 0 0 100px rgba(0,0,0,.08);
      background: #fff;
      .login-logo{
        text-align: center;
        height: 81px;
        cursor: pointer;
        margin-bottom: 24px;
        background: url('./img/logo.png') no-repeat;
        background-size: 240px;
        background-position:center;
      }
      .login-form-button{
          width: 100%;
      }
      .login-title{
        text-align: center;
        font-size: larger;
        color:   #6a3024;
        margin-bottom: 12px;
      }
      .login-version{
        font-size: small;
      }
      .login-Scan{
        width: 330px;
        margin-bottom: 12px;
      }
    }
    .lanuage{
      float: right;
    }
  }


@primary-color: #945926;@btn-font-size-lg: 18px;@btn-height-lg: 50px;@input-height-lg: 50px;@layout-body-background: #fafafa;@layout-footer-background: #f0f2f5;@menu-dark-bg: #6a3024;@menu-dark-submenu-bg: #945926;@layout-sider-background: #6a3024;@breadcrumb-font-size: 18px;@breadcrumb-icon-font-size: 18px;