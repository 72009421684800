
.textCenter{
    text-align: center
}

.textRight{
    text-align: right
}

.textLeft{
    text-align: left
}


h1{
    font-size: xx-large;
}

.hide{
    display: none;
}

.orderFrom{
    padding: 20px;
    min-height:360px;
    background: #fff;

    .orderHeadTable{
        width: 100%;
        margin: 12px 0;
        tr td{padding: 8px 0px 8px 5px;
            border-bottom: 1px dashed #666666;
            border-right: 1px dashed #666666;
            font-weight: 500;
            font-size: larger;}
        
    }

    .orderBtn{
        float: right;
        font-weight: 600;
        margin-bottom: 12px;
        .btnMargin{
            margin-right: 16px;
        }
        .ant-input-number-lg{
            width: 110px;
        }
        .ant-input-lg{
            width:200px;
        }
    }

    label{
        font-size: 20px;
    }

    input{
        font-size: 20px;
    }    
}

.timeRecordRow{
    font-size: larger;
}

.selfTimeRecord{
    font-size: larger;
    background-color: #ffe7ba;
}

.connected{
    color: #6a3024;
    float: right;
    font-size: 0.7em;
    padding-right: 2em;
    padding-top: 0.5em;
}

.orderModal{
    .ant-modal{
        width: 600px;
    }
    p{
        font-weight: bolder;
        font-size: large;
    }
}

.bigModal{
    .anticon{
        font-size: x-large;
    }

    .ant-modal-confirm-title{
        font-size:x-large;
    }

    .ant-modal-confirm-content{
        font-size: large;
        font-weight: 400;
        color: #333;
    }

    .ant-btn{
        height: 42px;
        font-size: 20px;
    }
}

.headerBtn{
    float: right;
    margin-right: 12px;
}

.thirdBtn{
    background-color:#ffcd8d;
}


@primary-color: #945926;@btn-font-size-lg: 18px;@btn-height-lg: 50px;@input-height-lg: 50px;@layout-body-background: #fafafa;@layout-footer-background: #f0f2f5;@menu-dark-bg: #6a3024;@menu-dark-submenu-bg: #945926;@layout-sider-background: #6a3024;@breadcrumb-font-size: 18px;@breadcrumb-icon-font-size: 18px;