body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root{
  height: 100%;
}

.loading_mask{
  position: fixed;left: 0; right:0;top: 0;bottom: 0;z-index:1002;
  background-color:rgba(0,0,0,0.12);
  }

.hide_mask{
  display: none;
}

.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

