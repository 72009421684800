

.reportbar{
    margin: 0.5rem 0;
    text-align: right;
    justify-content: flex-end;

    .summary{
        font-weight: normal;
        font-size: larger;
    }
}

.searchDate{
    .ant-tag-blue{
        color: #6a3024;
        background: #efd2b6;
        border-color:#9a765a; 
    }
}

.bigModal{
    .anticon{
        font-size: xx-large;
    }

    .ant-modal-confirm-title{
        font-size:x-large;
    }

    .ant-modal-confirm-content{
        font-size: large;
        font-weight: 500;
        color: #333;
    }
}
@primary-color: #945926;@btn-font-size-lg: 18px;@btn-height-lg: 50px;@input-height-lg: 50px;@layout-body-background: #fafafa;@layout-footer-background: #f0f2f5;@menu-dark-bg: #6a3024;@menu-dark-submenu-bg: #945926;@layout-sider-background: #6a3024;@breadcrumb-font-size: 18px;@breadcrumb-icon-font-size: 18px;